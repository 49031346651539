

h1, h2, h3, h4, h5, h6, [class^="MuiTypography-h"] {
  font-family: 'Kanit', sans-serif !important;
  font-weight: 600 !important;
}

Button{
  font-family: 'Kanit', sans-serif !important;
  font-weight: 300 !important;
}

.logo-login{
  height: 90vmin;
  max-height: 960px;
  pointer-events: none;
}
